exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-gift-index-tsx": () => import("./../../../src/pages/gift/index.tsx" /* webpackChunkName: "component---src-pages-gift-index-tsx" */),
  "component---src-pages-gift-orange-tsx": () => import("./../../../src/pages/gift/orange.tsx" /* webpackChunkName: "component---src-pages-gift-orange-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-estfootball-tsx": () => import("./../../../src/pages/projects/estfootball.tsx" /* webpackChunkName: "component---src-pages-projects-estfootball-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-paulsenregnskab-tsx": () => import("./../../../src/pages/projects/paulsenregnskab.tsx" /* webpackChunkName: "component---src-pages-projects-paulsenregnskab-tsx" */),
  "component---src-pages-projects-personal-soccer-coaching-tsx": () => import("./../../../src/pages/projects/personal-soccer-coaching.tsx" /* webpackChunkName: "component---src-pages-projects-personal-soccer-coaching-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */)
}

